#sp-menu {

  button[type="button"] {
    background-color: transparent;
  }

  @include grid-media($lg-grid) {
    display: none;
  }
}

.drawer-menu {
  z-index:9999;
  padding-top: $base-spacing;

  .drawer-brand {

    .site-nav__logo {
      padding-bottom: $base-spacing;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  &-item {

    color: #707070;

    &:hover {
      text-decoration: none;
    }
  }
}