.site-footer {
    position: relative;

    &::before {
        content:'';
        position: absolute;
        top: 0;
        left:0;
        width:100%;
        height: 6px;
        background-size:contain;
        background-image: url(../images/other/footer-bar-color.png);

        @include grid-media($lg-grid) {
            background-size: auto;
            height: 12px;
        }
    }

    &::after {
        content: '';
        position: absolute;
    }

    &__widget {
        padding: ($base-spacing * 2) 0;
        background-color: #F0EAE6;

        &--box {
            display: flex;
            flex-direction: column;
            padding:0 $small-spacing;

            @include grid-media($lg-grid) {
                padding:0 $base-spacing;
                flex-direction: row;
                justify-content: space-between;
            }

            @include grid-media($xl-grid) {
                padding:0;
            }
        }

        &--inner {
            flex: 1;

            p {
                font-size: .75rem;
                color: #707070;
            }
        }
    }

    &__inner {
        margin-top: -8px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:top left;
        background-image: url(../images/other/footer-bar.png);
    }

    &__copyright {
        padding: $base-spacing 0;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        color: #707070;
        font-size: .75rem;
    }
}