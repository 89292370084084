/* Print styles
   ----------------------------------------------------------------- */
@media print {
    * {
        background: transparent !important;
        color: #000 !important;
        /* Black prints faster: h5bp.com/s */
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a {
        text-decoration: underline;
        &:visited {
            text-decoration: underline;
        }
        &[href]:after {
            content: " (" attr(href) ")";
        }
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    /*
     * Don't show links for images, or javascript/internal links
     */
    .ir a:after {
        content: "";
    }
    a {
        &[href^="javascript:"]:after, &[href^="#"]:after {
            content: "";
        }
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
        /* h5bp.com/t */
    }
    tr {
        page-break-inside: avoid;
    }
    img {
        page-break-inside: avoid;
        max-width: 100% !important;
    }
    @page {
        margin: 0.5cm;
    }

    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
}