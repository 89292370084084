ul,
ol {
    padding: 0 0 0 1.5em;
}

dl {
    margin: 0;
}

dt {
    font-weight: normal;
    margin: 0;
}

dd {
    margin: 0;
}