// Import Now
//
// If you import this module directly, it will immediately output all the CSS
// needed to normalize default HTML elements across all browsers.
//
// ```
// @import "normalize/import-now";
// ```

@import '../normalize';
@include normalize();
