.wpcf7 {

  & &-submit {
    max-width: 240px;
    display: block;
    margin: 0 auto;
    border-radius: 0;
    text-align: center;
    padding: $base-spacing $base-spacing * 4;
    background-color: #71A3D0;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    font-size: .75rem;
    border-radius: 0;
    box-shadow: none;
    border-top: 0;
    border-right: 0;
    border-left:0;
    border-bottom: 1px solid #707070;
    transition: .3s;

    &:focus {
      transition: .3s;
      border-bottom: 1px solid #71A3D0;
    }
  }

  textarea {
    font-size: .75rem;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #707070;
    transition: .3s;

    &:focus {
      border: 1px solid #71A3D0;
      transition: .3s;
    }
  }
}