.site-nav {
  position: relative;
  z-index: index($z-indexes, global-nav);
  display: none;
  width: 100%;

  @include grid-media($lg-grid) {
    display: block;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    background-color: #e0e0e0;
  }

  &__inner {

  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include grid-media($lg-grid) {
      padding: $base-spacing 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    position: relative;
    list-style: none;
    font-size: .75rem;
  }

  a {
    display: block;
    position: relative;
    z-index: 1;
    padding: 16px;
    color: #707070;
  }
}