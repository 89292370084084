.section {
  padding: ($base-spacing * 1) $small-spacing;

  @include grid-media($xl-grid) {
    padding: ($base-spacing * 2) $base-spacing;
  }

  @media(min-width: 100em) {
    padding: ($base-spacing * 2) 0;
  }

  &__head {
    font-size: 1.5rem;
    color: #707070;
    padding: 0;
    margin: 0;
    font-weight: 600;

    h2 {
      font-size: 1.5rem;
      color: #707070;
      padding: 0;
      margin: 0;
      font-weight: 600;
    }

    p {
      color: #707070;
      font-size: .75rem;
    }
  }

  &--top {

    &__visual {
      padding: 0;
      position: relative;
    }

    &__what {
      background-size: contain;
      background-position: bottom;
      background-repeat: no-repeat;
      background-image: url(../images/other/what-bg.png);

      @include grid-media($lg-grid) {
        background-size: cover;
        background-position: center;
      }

      @include grid-media($xl-grid) {
        background-size: cover;
        background-position: initial;
      }
    }

    &__sdgs {
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: right 50%;
      background-image: url(../images/other/sdgs-bg-2.png);
    }

    &__work {
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../images/other/work-bg.png);

      @include grid-media($lg-grid) {
        background-position:center;
      }

      @media (min-width: 100em) {
        background-position: initial;
      }
    }

    &__mission {
      background-size: cover;
      background-position: 15%;
      background-repeat: no-repeat;
      background-image: url(../images/other/mission-bg.png);

      @include grid-media($lg-grid) {
        background-position: 30%;
      }

      @include grid-media($xl-grid) {
        background-size: contain;
        background-position: initial;

      }
    }

    &__goods {
      padding:0 $small-spacing;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom center;
      background-image: url(../images/other/goods-bg.png);

      @include grid-media($lg-grid) {
        padding:$base-spacing 0;
      }

      @include grid-media($xl-grid) {
        padding:$base-spacing;
      }

      @media(min-width: 100em) {
        padding: 0;
      }
    }
  }
}