.contact-section {

  .section__head {

    h2 {
      margin: 0;
      text-align: center;
    }

    p {
      margin: 0;
    }
  }
}