%container {
    max-width: ($bp-x-large + ($gutter * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $gutter;
    padding-left: $gutter;
}

.container {
    @extend %container;

    &--grid {
        @include grid-visual;
        @include grid-container;
        padding-right: 0;
        padding-left: 0;
    }

    &--top {
        max-width: 80em;
        padding-right: 0;
        padding-left: 0;
    }

    &--nav {
        max-width: 102.5em;
        padding-right: 0;
        padding-left: 0;
    }

    &--mini {
        max-width: 37.5em;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
    }
}