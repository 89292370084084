.site-wrapper {
    position: relative;

    &--drawer-left {
        margin-left: 320px;
    }

    &--drawer-right {
        margin-right: 320px;
    }

    &--drawer-none {
        margin-right: auto;
        margin-left: auto;
    }
}