// Typography
*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    color: $base-font-color;
    font-family: $base-font-family;
    background-color: $base-background-color;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
    line-height: $base-line-height-ratio;
}

h1,
.h1 {
    font-size: modular-scale(3);
}

h2,
.h2 {
    font-size: modular-scale(2);
}

h3,
.h3 {
    font-size: modular-scale(1);
}

h4,
.h4 {
    font-size: modular-scale(0);
}

h5,
.h5 {
    font-size: modular-scale(-1);
}

h6,
.h6 {
    font-size: modular-scale(-2);
}

p,
ul,
ol,
pre,
table,
blockquote,
hr,
address {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
    font-size: modular-scale(0);
}

hr {
    border-top: none;
    border-bottom: $base-border;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

pre {
    overflow: auto;
    padding: $base-spacing;
    color: $white;
    background: $black;
    font-family: $font-stack-consolas;
    font-size: modular-scale(-1);
}

code,
kbd,
tt,
var {
    color: $white;
    background: $black;
    font-family: $font-stack-consolas;
    font-size: modular-scale(-1);
}

abbr,
acronym {
    border-bottom: 1px dotted $gray;
    cursor: help;
}

mark,
ins {
    background: #fff9c0;
    text-decoration: none;
}

big {
    font-size: modular-scale(1);
}
