.goods-section {
  position: relative;
  padding-bottom: $base-spacing * 5;

  @include grid-media($sm-grid) {
    padding-bottom: $base-spacing * 8.333;
  }

  @include grid-media($xl-grid) {
    padding-bottom: $base-spacing * 15;
  }

  &__movie {
    padding: ($base-spacing * 2) 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include grid-media($xl-grid) {
      flex-direction: row;
      justify-content: space-between;
    }

    &--inner {
      margin-bottom: $base-spacing;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__logo {

    @include grid-media($xl-grid) {
      position: absolute;
      top: -360px;
      left: 10%;
    }

    @media(min-width: 100em) {
      top: -240px;
    }

    img {
      width: 50%;
      display: block;
      margin: $base-spacing auto;

      @include grid-media($xl-grid) {
        width: 50%;
        margin: 0;
      }
    }
  }

  &__list {

    .section__head {
      text-align: center;
      margin-bottom: $base-spacing * 2;

      h2 {

      }

      p {
        margin:0;
        font-size: .75rem;
      }
    }

    &--goods {
      display: flex;
      flex-direction: column;

      @include grid-media($lg-grid) {
        flex-direction: row;
        justify-content: space-between;
      }

      &__inner {
        margin-bottom: $base-spacing;

        @include grid-media($lg-grid) {
          margin-bottom: 0;
          margin-right: $small-spacing;
        }

        &:last-child {
          margin-bottom:0;
          margin-right: 0;
        }
      }
    }

    &--price {

      @include grid-media($lg-grid) {
        margin-bottom: $small-spacing;
      }

      h3 {
        margin:0;
        color: #707070;
        font-size: 1rem;
        font-weight: 500;
      }

      p {
        margin: 0;
        color: #707070;
        font-size: .75rem;
      }
    }

    &--btn {

      a {
        font-size: .75rem;
        background-color: #707070;
        color: white;
        padding: $small-spacing $base-spacing;

        @include grid-media($xl-grid) {
          padding: $small-spacing ($base-spacing * 2);

        }
      }
    }

    &--info {
      display: flex;
      align-items: center;
      margin-top:$small-spacing;
      justify-content: space-between;

      @include grid-media($lg-grid) {
        flex-direction: column;
        justify-content: flex-start;
      }

      @include grid-media($xl-grid) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
}