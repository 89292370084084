.sdgs-section {
  position: relative;

  &__head {
    text-align: center;

    @include grid-media($lg-grid) {
      text-align: left;
      position: absolute;
      left: 0;
      top: 0;
    }

    h2 {
      font-weight: 300;
      color: #707070;
      line-height: 1.06;

      @include grid-media($lg-grid) {
        font-size: 2.9375rem;
        margin-top: 0;
      }

      @include grid-media($xl-grid) {
        margin-top: $base-spacing;
      }

      span {
        color: #36B8E9;
      }
    }

    &__subtitle {

      @include grid-media($lg-grid) {
        position: absolute;
        right: 72px;
        top: -108px;
      }

      @include grid-media($xl-grid) {
        top: -108px;
      }

      @media (min-width: 100em) {
        //top: -56px;
      }

      h3 {
        color: #707070;
        font-size: 1.5rem;
        position: relative;
        text-align: center;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
          background-image: url(../images/other/arrow-down.png);
        }

        @include grid-media($lg-grid) {
          text-align: left;
        }
      }
    }

    p {
      font-size: .75rem;
      color: #707070;
    }
  }

  &__all {
    margin-top: $base-spacing * 2;
  }

  &__box {
    display: block;
    margin: 0 auto;


    @include grid-media($xl-grid) {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .pc {
    display: none;

    @include grid-media($lg-grid) {
      display: block;
    }
  }

  .sp {
    @include grid-media($lg-grid) {
      display: none;
    }
  }
}