.work-section {

  @include grid-media($lg-grid) {
    padding-bottom: $base-spacing * 15;
  }

  &__head {
    text-align: center;

    h2 {
      margin-bottom: $base-spacing * 2;
    }

    h3 {
      color: white;
      font-weight: 600;
      margin:0;

      @include grid-media($lg-grid) {
        font-size: 2.9375rem;
      }
    }

    p {
      color: white;
      margin: 0;
      font-size: 1.25rem;
    }
  }
}