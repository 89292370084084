.top-visual {
  max-height: 100vh;
  padding:0 $small-spacing;

  @include grid-media($sm-grid) {
    padding: ($base-spacing * 4) $small-spacing;
  }

  @include grid-media($lg-grid) {
    display: flex;
    //align-items: center;
    height: auto;
    top: auto;
    transform: none;
    position: static;
    padding: ($base-spacing * 10.25) $base-spacing;
  }

  @include grid-media($xl-grid) {
    padding: ($base-spacing * 6) $base-spacing;
  }

  @media (min-width: 110em) {
    padding: ($base-spacing * 9) 0;
  }

  &__menu {
    display: none;

    @include grid-media($lg-grid) {
      display: block;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        font-size: .75rem;
        margin-bottom: $base-spacing;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: #707070;
        }
      }
    }
  }

  &__inner {
    position: relative;

    h1.site-logo {
      margin: 0;
      padding: $base-spacing;
      text-align: center;

      @include grid-media($lg-grid) {
        padding: $base-spacing 0;
      }

      @include grid-media($xl-grid) {
        text-align: left;
        padding: ($base-spacing * 4) 0;
      }

      img {
        width: 50%;

        @include grid-media($lg-grid) {
          width: 100%;
        }

        @include grid-media($xl-grid) {
          width: auto;
        }
      }
    }

    &--bg {
      position: relative;
    }

    &--text {
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20%;
      font-family: sans-serif;
      color: white;
      font-weight: 600;
      font-size: 1.875rem;

      @include grid-media($lg-grid) {
        font-size: 2.25rem;
      }

      @include grid-media($xl-grid) {
        font-size: 2.9375rem;
      }
    }
  }
}