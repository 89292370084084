.grid {

    &-list {
        @include clearfix;

        &__item {

            @include grid-media($sm-grid) {
                @for $i from 1 through $grid-columns {
                    &.column-#{$i} {
                        @include grid-column($i);
                    }
                }

                @for $i from 1 through $grid-columns {
                    &.push-#{$i} {
                        @include grid-push($i);
                    }
                }

                @for $i from 1 through $grid-columns {
                    &.pull-#{$i} {
                        @include grid-push((-1 * $i));
                    }
                }
            }
        }
    }
}