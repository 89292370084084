fieldset {
    background-color: transparent;
    margin: $base-spacing 0;
    padding: $small-spacing;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: $form-box-shadow;
}

legend {
    font-weight: 600;
    margin-bottom: $small-spacing / 2;
    padding: 0;
}

label {
    display: block;
    font-weight: 600;
    margin-bottom: $small-spacing / 2;

    > input,
    > select,
    > textarea {
        margin-top: $small-spacing / 2;
    }
}

input,
select,
textarea {
    display: block;
    font-family: $base-font-family;
    font-size: $base-font-size;
}

#{$all-text-inputs},
select {
    appearance: none;
    background-color: $base-background-color;
    border: $base-border;
    border-radius: $base-border-radius;
    box-shadow: $form-box-shadow;
    box-sizing: border-box;
    margin-bottom: $small-spacing;
    padding: $base-spacing / 3;
    transition: border-color $base-duration $base-timing;
    width: 100%;

    &:hover {
        border-color: shade($base-border-color, 20%);
    }

    &:focus {
        border-color: $action-color;
        box-shadow: $form-box-shadow-focus;
        outline: none;
    }

    &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;

        &:hover {
            border: $base-border;
        }
    }

    &::placeholder,
    &:placeholder-shown,
    &::-webkit-input-placeholder,
    &:-moz-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder {
        color: tint($base-font-color, 40%);
    }
}

button,
input,
optgroup,
select,
textarea {
    max-width: 100%;
}

input {
    &[type="text"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="telephone"],
    &[type="number"],
    &[type="date"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="month"],
    &[type="week"],
    &[type="time"],
    &[type="password"],
    textarea {

    }
}

input[type="submit"],
button {

}

textarea {
    resize: vertical;
}

[type="checkbox"],
[type="radio"] {
    display: inline;
    margin-right: $small-spacing / 2;
}

[type="file"] {
    margin-bottom: $small-spacing;
    width: 100%;
}

//select {
//    appearance: none;
//    margin-bottom: $small-spacing;
//    background-color: $base-background-color;
//}