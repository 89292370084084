img {
  height: auto;
  border: 0;
  vertical-align: middle;
}

svg {
  height: 100%;
}

img,
picture,
figure {
  margin: 0;
}

progress {
  display: inline-block;
}

img,
svg,
picture,
embed,
iframe,
object,
video {
  max-width: 100%;
}

embed,
iframe,
object,
video,
progress {
  vertical-align: baseline;
}