.mission-section {

  @include grid-media($lg-grid) {
    padding-left: 50%;
  }

  .section__head {
    h2 {
      color: white;
    }
  }

  p {
    color: white;
    font-weight: 300;
    font-size: .75rem;
  }

  &__poster {
    display: flex;
    justify-content: space-between;

    &--inner {

      img {

      }
    }
  }
}