//************************************
// Color Settings
//************************************
// ========================
// Brand Color
// ========================
$brand-color: (
    'wordpress': #21759b,
);

$wordpress:     #21759b;
$vimeo:         #1ab7ea;
$linkedin:      #0079ba;
$twitter:       #55acee;
$facebook:      #3b5998;
$googleplus:    #dc4e41;
$hatena:        #00a5de;
$pocket:        #ef3f56;
$line:          #5ae628;
$instagram:     #125688;
$tumblr:        #36465d;
$flickr-blue:   #0063dc;
$flickr-pink:   #ff0084;
$rss:           #ffa500;
$feedly:        #6cc655;
$evernote:      #79bd28;
$vine:          #00b389;
$amazon:        #ff9900;
$mixi:          #d1ad59;
$pinterest:     #bd081c;
$youtube:       #cd201f;
$html5:         #e34f26;
$css3:          #e34c26;
$bing:          #ffb900;
$rakuten:       #bf0000;
$yahoo:         #780099;
$skype:         #12a5f4;

// ========================
// Custom Theme Color
// ========================
// Basic color
$black:         #000;
$white:         #fff;
$white-gray:    #f1f1f1;
$gray:          lighten(desaturate($black, 50%), 40%);
$green:         #7ad03a;
$red:           #a00;
$orange:        #ffba00;
$blue:          #207fc0;
$dark-gray:     tint($black, 16%);
$medium-gray:   tint($black, 48%);
$light-gray:    tint($black, 88%);
$alpha-black:   rgba($black, .1);
$alpha-white:   rgba($white, .1);

// *** Color Harmonies ***
$primary: #c6b348;

// Color Wheel
$color-wheel: (
    '01': $primary,
    '02': adjust_hue($primary, 30deg),
    '03': adjust_hue($primary, 60deg),
    '04': adjust_hue($primary, 90deg),
    '05': adjust_hue($primary, 120deg),
    '06': adjust_hue($primary, 150deg),
    '07': adjust_hue($primary, 180deg),
    '08': adjust_hue($primary, 210deg),
    '09': adjust_hue($primary, 240deg),
    '10': adjust_hue($primary, 270deg),
    '11': adjust_hue($primary, 300deg),
    '12': adjust_hue($primary, 330deg),
);
@function color-wheel($key) {
    @return map-get($color-wheel, $key);
}

// Dyad (Complementary)
$dyad:                      complement($primary);

// Analogous
$analogous-first:           adjust-hue($primary, 30deg);
$complement-second:         adjust-hue($primary, -30deg);

// Triad
$triad-first:               adjust-hue($primary, 120deg);
$triad-second:              adjust-hue($primary, -120deg);

// Split-Complementary
$split-complement-first:    adjust-hue($dyad, 30deg);
$split-complement-second:   adjust-hue($dyad, -30deg);

$secondary: $dyad;


// Link and Button color.
$action-color:                  $primary;
$action-color-text:             desaturate(lighten($primary, 40%), 16%);
$action-color-hover:            desaturate(darken($action-color, 8%), 8%);

$action-color-secondary:        $secondary;
$action-color-secondary-text:   desaturate(lighten($secondary, 40%), 16%);
$action-color-secondary-hover:  desaturate(darken($secondary, 8%), 8%);

//$main-color: $primary;
//$sub-color: $gray;
//$accent-color: $blue;
//$body-text-color: $black;
//$body-background-color: $white;