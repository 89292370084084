// Style Guide: Atomic Design
/* ########################################################################### *
   # 00 Foundation
 * ########################################################################### */
@import "00_foundation/lib/_bourbon.scss";
@import "00_foundation/lib/_neat.scss";
@import "00_foundation/variables/_variables";
@import "00_foundation/lib/_normalize.scss";
@import "00_foundation/lib/normalize/_import-now.scss";
@import "00_foundation/_typography.scss";
@import "00_foundation/lib/_font-awesome.scss";

/* ########################################################################### *
   # 01 Atom
 * ########################################################################### */
// atom:inject
@import "01_atom/_a-button.scss";
@import "01_atom/_a-container.scss";
@import "01_atom/_a-forms.scss";
@import "01_atom/_a-grid.scss";
@import "01_atom/_a-label.scss";
@import "01_atom/_a-link.scss";
@import "01_atom/_a-lists.scss";
@import "01_atom/_a-media.scss";
@import "01_atom/_a-screen-reader.scss";
@import "01_atom/_a-table.scss";
// endinject

/* ########################################################################### *
   # 02 Molecule
 * ########################################################################### */
// molecule:inject
@import "02_molecule/_m-breadcrumb.scss";
@import "02_molecule/_m-site-id.scss";
@import "02_molecule/_m-social-button.scss";
@import "02_molecule/_m-wpcf7.scss";
// endinject

/* ########################################################################### *
   # 03 Organism
 * ########################################################################### */
// organism:inject
@import "03_organism/_o-contents.scss";
@import "03_organism/_o-footer.scss";
@import "03_organism/_o-main.scss";
@import "03_organism/_o-site-footer.scss";
@import "03_organism/_o-site-header.scss";
@import "03_organism/_o-site-nav.scss";
@import "03_organism/_o-site-sidebar.scss";
@import "03_organism/_o-site-wrapper.scss";
@import "03_organism/_o-sp-menu.scss";
@import "03_organism/_o-widget.scss";
// endinject

/* ########################################################################### *
   # 04 Template
 * ########################################################################### */
// template:inject
@import "04_template/_t-contact-section.scss";
@import "04_template/_t-goods-section.scss";
@import "04_template/_t-mission.section.scss";
@import "04_template/_t-partner-section.scss";
@import "04_template/_t-sdgs-section.scss";
@import "04_template/_t-section.scss";
@import "04_template/_t-top-visual.scss";
@import "04_template/_t-what-section.scss";
@import "04_template/_t-work_section.scss";
// endinject

/* ########################################################################### *
   # 05 Page
 * ########################################################################### */
// page:inject
@import "05_page/_p-blog.scss";
@import "05_page/_p-home.scss";
@import "05_page/_p-page.scss";
@import "05_page/_p-print.scss";
@import "05_page/_p-single.scss";
// endinject