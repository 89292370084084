.widget {
    margin-bottom: ($base-spacing * 2);
    padding: $base-spacing;

    &:last-child {
        margin-bottom: 0;
    }

    &__title {
        margin-top: 0;
    }
}