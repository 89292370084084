.what-section {
  display: flex;
  flex-direction: column;

  @include grid-media($lg-grid) {
    align-items: center;
    flex-direction: row;
  }

  &__inner {
    position: relative;
    text-align: center;

    @include grid-media($lg-grid) {
      flex: 1;

    }

    img {
      position: relative;
    }

    .what-bsc {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include grid-media($lg-grid) {

      }
    }
  }

  &__content {
    padding-top: $base-spacing;

    @include grid-media($lg-grid) {
      padding: 0 $base-spacing;
    }

    @include grid-media($xl-grid) {
      padding-left: $base-spacing * 5;
      padding-right: 0;
    }

    p {
      font-size: .75rem;
      color: #707070;
      text-align: left;
    }
  }
}