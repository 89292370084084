.partner-section {

  .section__head {
    text-align: center;
    margin-bottom: $base-spacing * 2;

    h2 {
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  &__inner {

  }

  h3 {
    margin: 0;
    color: #707070;
    font-size: 1.5rem;
    text-align: center;
  }

  &__table {
    margin-bottom: $base-spacing * 2;
    border: 1px solid #e0e0e0;

    tr {

      td {
        padding: $small-spacing;
        color: #707070;
        text-align: center;
        border-right: 1px solid #e0e0e0;
      }
    }
  }
}